import React, { useState, useEffect } from 'react';

const Step7 = () => {
  const [styles, setStyles] = useState(getStyles(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setStyles(getStyles(window.innerWidth));
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer style={styles.footer}>
      <p style={styles.text}>Uno de nuestros especialistas te contactará con la información completa</p>
    </footer>
  );
};

const getStyles = (width) => {
  let textStyle = {
    color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', fontWeight: 700,
    fontSize: '48px', lineHeight: '58px',  margin: 0, textAlign: 'center',
  };

  if (width <= 430) {
    textStyle.fontSize = '15px';  textStyle.lineHeight = '24px';
  } else if (width <= 768) {
    textStyle.fontSize = '20px';  textStyle.lineHeight = '29px';
  }

  return {
    footer: {
      backgroundColor: '#3F514F',  padding: '20px 0',  textAlign: 'center',
    },
    text: textStyle,
  };
};

export default Step7;