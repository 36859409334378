import React from 'react';
import './styles/Step2.css';

const Step2 = ({ scrollToStep6 }) => { 
  return (
    <div className='contenedore'>
      <center>
        <div className='one'>¿Siempre has querido invertir en inmuebles?</div>
        <div className='dos'>Ahora tienes la oportunidad junto a los expertos</div>
        <div>
          <span className='one'>Conviértete en un </span>
          <span className='tres'>inversionista exitoso</span>
        </div>
        <div className='cuatro'>participando en proyectos con montos desde</div>
        <div className='cinco'>$1,000*</div>
        <button className='seis' onClick={scrollToStep6}>Más información</button> 
      </center>
    </div>
  );
};

export default Step2;