import React, { useState, useEffect } from 'react';
import vectorImage from '../img/Vectorre.svg'; 
import '../components/styles/Step5.css';
import projectsData from '../components/data/projects.json';

// Importacion de imágenes
import img1 from '../img/proyects/amatecampo-villas-1.jpg';
import img2 from '../img/proyects/Asset-7-1.jpg';
import img3 from '../img/proyects/Asset-9-1.jpg';
import img4 from '../img/proyects/Asset-10-1.jpg';
import img5 from '../img/proyects/Asset-11-1.jpg';
import img6 from '../img/proyects/Asset-11-2.jpg';
import img7 from '../img/proyects/Asset-12-1.jpg';
import img8 from '../img/proyects/Asset-13-1.jpg';
import img9 from '../img/proyects/Asset-14-1.jpg';
import img10 from '../img/proyects/Asset-15-1.jpg';
import img11 from '../img/proyects/Asset-16-1.jpg';

// Mapeo de las imágenes con sus respectivos datos
const images = [
    { src: img1, ...projectsData[0] },
    { src: img2, ...projectsData[1] },
    { src: img3, ...projectsData[2] },
    { src: img4, ...projectsData[3] },
    { src: img5, ...projectsData[4] },
    { src: img6, ...projectsData[5] },
    { src: img7, ...projectsData[6] },
    { src: img8, ...projectsData[7] },
    { src: img9, ...projectsData[8] },
    { src: img10, ...projectsData[9] },
    { src: img11, ...projectsData[10] }
];

const Step5 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 15000); // Cambia de imagen cada 15 segundos

        return () => clearInterval(interval);
    }, []);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const currentImage = images[currentIndex] || {};

    return (
        <div className="slider-container">
            {currentImage.src && (
                <>
                    <img src={currentImage.src} alt={`Slide ${currentIndex + 1}`} className="slider-image" />

                    <div className="text-container">
                        <h2 className="title">{currentImage.title}</h2>
                        <div className="decorative-line"></div>
                        <div className="description">
                            {currentImage.description.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </div>
                    </div>
                </>
            )}

            <button className="slider-button prev-button" onClick={prevSlide}>
                &#10094;
            </button>
            <button className="slider-button next-button" onClick={nextSlide}>
                &#10095;
            </button>

            {/* Imagen y texto adicionales */}
            <div className="vector-container">
                <img src={vectorImage} alt="Vector" className="vector-image" />
                <h2 className="vector-text">
                    <span>¡Sé parte de esta nueva</span><br/>
                    <span>dinámica de inversión!</span>
                </h2>
            </div>
        </div>
    );
};

export default Step5;