import React from 'react';
import logo from '../img/-_LOGO 3T ORIGINAL 1.svg';
import '../components/styles/header.css';

const Header = () => {
  return (
    <header>
      <img src={logo} alt="Logo" />
    </header>
  );
};

export default Header;