import React, { useState, forwardRef } from 'react';
import './CustomComponent6.css';

const Step6 = forwardRef((props, ref) => {
  const [formValues, setFormValues] = useState({
    'First Name': '',
    'Last Name': '',
    Mobile: '',
    Email: '',
    LEADCF3: '-None-',
    LEADCF5: '-None-',
    LEADCF7: '-None-',
    Lead_Source: '-None-',
  });
  const [showModal, setShowModal] = useState(false);

  // Función para enviar los datos a Zoho CRM
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkMandatory3901113000022367326()) {
      // Construir el cuerpo del formulario
      const formData = new URLSearchParams();
      for (let key in formValues) {
        formData.append(key, formValues[key]);
      }
      formData.append('xnQsjsdp', 'a86557461efb139e6c99f574bdb7e0ddee49cc99bc59159649a26110c2ac1ec0');
      formData.append('xmIwtLD', 'c75717dc21809ab412e9c1b8876cd2f0a06a4361615387122d890746cbbb00397d22b9b1b33dd5ed3bd1880847258983');
      formData.append('actionType', 'TGVhZHM=');

      // Realizar la solicitud POST a Zoho CRM
      try {
        fetch('https://crm.zoho.com/crm/WebToLeadForm', {
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          mode: 'no-cors',
        });
        // Mostrar modal de éxito y redirigir
        setShowModal(true);
        setTimeout(() => {
          window.location.href = "https://www.3torres.com.sv/";
        }, 3000);
        // Limpiar el formulario
        setFormValues({
          'First Name': '',
          'Last Name': '',
          Mobile: '',
          Email: '',
          LEADCF3: '-None-',
          LEADCF5: '-None-',
          LEADCF7: '-None-',
          Lead_Source: '-None-',
        });
      } catch (error) {
        console.error('Error al enviar el formulario:', error);
      }
    }
  };

  const checkMandatory3901113000022367326 = () => {
    const mndFileds = ['First Name', 'Last Name', 'LEADCF5', 'LEADCF7'];
    const fldLangVal = [
      'Nombres',
      'Apellidos',
      'Monto de inversión',
      'Período de inversión',
    ];
    for (let i = 0; i < mndFileds.length; i++) {
      const fieldObj = formValues[mndFileds[i]];
      if (!fieldObj || fieldObj.trim().length === 0 || fieldObj === '-None-') {
        alert(fldLangVal[i] + ' no puede estar vacío.');
        return false;
      }
    }
    return validateEmail3901113000022367326();
  };

  const validateEmail3901113000022367326 = () => {
    const emailVal = formValues.Email.trim();
    if (emailVal.length !== 0) {
      const atpos = emailVal.indexOf('@');
      const dotpos = emailVal.lastIndexOf('.');
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
        alert('Introduzca una dirección de correo electrónico válida.');
        return false;
      }
    }
    return true;
  };

  return (
    <div ref={ref} className="container">
      <div className="header">
        <p>¡Bienvenido al mundo de las Inversiones Inmobiliarias!</p>
      </div>
      <form
        id="webform3901113000022367326"
        className="form"
        name="WebToLeads3901113000022367326"
        onSubmit={handleSubmit}
        acceptCharset="UTF-8"
      >
        <div className="column-left"> {/* Column left */}
          <div className="input-container">
            <label className="label" htmlFor="First_Name">Nombres<span style={{ color: 'red' }}></span></label>
            <input
              type="text"
              id="First_Name"
              aria-required="true"
              aria-label="First Name"
              name="First Name"
              aria-valuemax="40"
              maxLength="40"
              value={formValues['First Name']}
              onChange={(e) => setFormValues({ ...formValues, 'First Name': e.target.value })}
              className="input"
            />
            <div className="zcwf_col_help"></div>
          </div>
          <div className="input-container">
            <label className="label" htmlFor="Last_Name">Apellidos<span style={{ color: 'red' }}></span></label>
            <input
              type="text"
              id="Last_Name"
              aria-required="true"
              aria-label="Last Name"
              name="Last Name"
              aria-valuemax="80"
              maxLength="80"
              value={formValues['Last Name']}
              onChange={(e) => setFormValues({ ...formValues, 'Last Name': e.target.value })}
              className="input"
            />
            <div className="zcwf_col_help"></div>
          </div>
          <div className="input-container">
            <label className="label" htmlFor="Mobile">Móvil</label>
            <input
              type="text"
              id="Mobile"
              aria-required="false"
              aria-label="Mobile"
              name="Mobile"
              aria-valuemax="30"
              maxLength="30"
              value={formValues.Mobile}
              onChange={(e) => setFormValues({ ...formValues, Mobile: e.target.value })}
              className="input"
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>

        <div className="column-right"> {/* Column right */}
          <div className="input-container">
            <label className="label" htmlFor="Email">Correo electrónico</label>
            <input
              type="text"
              ftype="email"
              autoComplete="false"
              id="Email"
              aria-required="false"
              aria-label="Email"
              name="Email"
              aria-valuemax="100"
              crmlabel=""
              maxLength="100"
              value={formValues.Email}
              onChange={(e) => setFormValues({ ...formValues, Email: e.target.value })}
              className="input"
            />
            <div className="zcwf_col_help"></div>
          </div>
          <div className="input-container">
            <label className="label" htmlFor="LEADCF3">Nacionalidad</label>
            <select
              className="input"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="listbox"
              id="LEADCF3"
              onChange={(e) => setFormValues({ ...formValues, LEADCF3: e.target.value })}
              aria-required="false"
              aria-label="LEADCF3"
              name="LEADCF3"
              value={formValues.LEADCF3}
            >
              <option value="-None-">-None-</option>
              <option value="Salvadoreño">Salvadoreño</option>
              <option value="Salvadoreño en el exterior">Salvadoreño en el exterior</option>
              <option value="Extranjero residente en El Salvador">
                Extranjero residente en El Salvador
              </option>
              <option value="Extranjero">Extranjero</option>
            </select>
            <div className="zcwf_col_help"></div>
          </div>
          <div className="input-container">
            <label className="label" htmlFor="LEADCF5">Monto de inversión<span style={{ color: 'red' }}>*</span></label>
            <select
              className="input"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="listbox"
              id="LEADCF5"
              onChange={(e) => setFormValues({ ...formValues, LEADCF5: e.target.value })}
              aria-required="true"
              aria-label="LEADCF5"
              name="LEADCF5"
              value={formValues.LEADCF5}
            >
              <option value="-None-">-None-</option>
              <option value="$500 - $1,200">$500 - $1,200</option>
              <option value="$1,201 - $2,000">$1,201 - $2,000</option>
              <option value="$2,001 - $4,000">$2,001 - $4,000</option>
              <option value="$4,001 - $6,000">$4,001 - $6,000</option>
            </select>
            <div className="zcwf_col_help"></div>
          </div>
          <div className="input-container">
            <label className="label" htmlFor="LEADCF7">Plazo de inversión<span style={{ color: 'red' }}>*</span></label>
            <select
              className="input"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="listbox"
              id="LEADCF7"
              onChange={(e) => setFormValues({ ...formValues, LEADCF7: e.target.value })}
              aria-required="true"
              aria-label="LEADCF7"
              name="LEADCF7"
              value={formValues.LEADCF7}
            >
              <option value="-None-">-None-</option>
              <option value="Corto (menos de 2 años)">Corto (menos de 2 años)</option>
              <option value="Mediano (de 2 a 5 años)">Mediano (de 2 a 5 años)</option>
              <option value="Largo (5 o más años)">Largo (5 o más años)</option>
            </select>
            <div className="zcwf_col_help"></div>
          </div>
        </div>

        <div className="button-container"> {/* Botón de enviar */}
          <button type="submit" className="submit-button">Enviar</button>
        </div>
      </form>
      <br></br>
      <br></br>
      <br></br>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close"
              onClick={() => {
                setShowModal(false);
                window.location.href = "https://www.3torres.com.sv/";
              }}
            >
              &times;
            </span>
            <h2>¡Formulario enviado con éxito!</h2>
            <p>Gracias por compartir tus datos. Pronto serás contactado.</p>
          </div>
        </div>
      )}
    </div>
  );
});

export default Step6;