import React from 'react';
import backgroundImage from '../img/img1-heredado-para-web.jpg';
import frameImage from '../img/Frame.svg';
import logoImage from '../img/-_LOGO 3T NARANJA BLANCO 1.svg';
import '../components/styles/Step1.css';

const Step1 = () => {
  return (
    <div className="containeri">
      <img src={backgroundImage} alt="Background" className="backgroundImage" />
      <div className="overlayContainer">
        <img src={frameImage} alt="Frame" className="frameImage" />
        <div className='camo'></div>
        <div className="mobileLogoBox">
          <img src={logoImage} alt="Logo" className="mobileLogoImage" />
        </div>
      </div>
      <div className="logoContainer">
        <img src={logoImage} alt="Logo" className="logoImage" />
      </div>
    </div>
  );
};

export default Step1;