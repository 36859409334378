import React from 'react';
import vector6 from '../img/Vector 6.svg';
import logo3T from '../img/-_LOGO 3T ORIGINAL 1.svg';
import '../components/styles/Step4.css';

const content = [
  {
    title: 'Misión',
    text: 'Proveer los servicios de diseño y construcción de obras, generando calidad, eficiencia y productividad garantizando así la satisfacción de las necesidades y expectativas de nuestros clientes.',
  },
  {
    title: 'Visión',
    text: 'Consolidarnos como una empresa líder, reconocida por su innovación, calidad y servicio al cliente, mejorando constantemente la productividad, incrementando nuestra participación en el mercado y contribuyendo al desarrollo económico y social del país.',
  },
];

const Step4 = () => {
  return (
    <div className="step4-container">
      <div className="step4-content">
        <p className="step4-paragraph">
          3 TORRES es una desarrolladora <strong>100% salvadoreña</strong> con más de <strong>22 años de trayectoria.</strong>
        </p>
        <p className="step4-paragraph">
          La componen 200 colaboradores de diferentes disciplinas y a la fecha cuenta con una cartera que sobrepasa los <strong>1,300 clientes</strong>.
        </p>
        
        <div className="step4-info">
          {content.map((item, index) => (
            <React.Fragment key={index}>
              <h2 className="step4-title">{item.title}</h2>
              <p className="step4-paragraph">{item.text}</p>
              <img 
                src={vector6} 
                alt="Decorative line" 
                className="step4-vector"
              />
            </React.Fragment>
          ))}
          <h2 className="step4-final-title">Trayectoria de</h2>
          <img 
            src={logo3T} 
            alt="3T Logo" 
            className="step4-logo"
          />
        </div>
      </div>
    </div>
  );
}

export default Step4;