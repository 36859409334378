import React from 'react';
import Layer1 from '../img/proyects/DSC_5679.jpg';
import Vector from '../img/Vector.svg';
import '../components/styles/Step3.css'; 

const Step3 = () => {
  return (
    <div className="step3-container">
      <img src={Layer1} alt="Layer 1" className="background-layer" />
      <div className="overlay">
        <img src={Vector} alt="Vector" className="overlay-vector" />
        <h1 className="titlee">¿Por qué invertir junto a 3 Torres?</h1>
      </div>
    </div>
  );
};

export default Step3;