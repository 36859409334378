import React, { useEffect, useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';

function App() {
  const step6Ref = useRef(null); 

  const scrollToStep6 = () => {
    if (step6Ref.current) {
      step6Ref.current.scrollIntoView({ behavior: 'smooth' }); 
    }
  };

  useEffect(() => {
    const setScreenRatio = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const ratio = width / height;

      if (ratio > 1) {
        localStorage.setItem('ratio', 'true');
      } else if (ratio < 1) {
        localStorage.setItem('ratio', 'false');
      } else {
        localStorage.setItem('ratio', 'undefined');
      }
    };

    setScreenRatio();
    window.addEventListener('resize', setScreenRatio);

    return () => {
      window.removeEventListener('resize', setScreenRatio);
    };
  }, []);

  return (
    <div className="App">
      <Header />
      <Step1 />
      <Step2 scrollToStep6={scrollToStep6} /> 
      <Step3 />
      <Step4 />
      <Step5 />
      <Step6 ref={step6Ref} />
      <Step7 />
    </div>
  );
}

export default App;